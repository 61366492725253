<template>
  <div class="search-input" :class="{'expanded': expanded}">
    <v-text-field
      v-model="model"
      class="mr-1"
      prepend-inner-icon="mdi-magnify"
      dense
      outlined
      rounded
      hide-details
      @click:prepend-inner="expand"
      >
    </v-text-field>
    <v-btn
      v-if="expanded"
      class="search-input-close"
      icon
      @click="close">
      <v-icon>
        mdi-window-close
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    expand () {
      this.expanded = true
    },
    close () {
      this.expanded = false
      this.model = ''
    }
  }
}
</script>

<style lang="scss">
  .search-input {
    .v-text-field--rounded > .v-input__control > div.v-input__slot {
      padding: 1px 8px;
    }

    .v-icon.v-icon {
      font-size: 1.5rem;
    }
  }
</style>

<style lang="scss" scoped>
  .search-input {
    display: flex;
    padding: 0px;
    width: 40px;
    transition: width .2s;

    &.expanded {
      width: 100%;
    }

    .v-btn.search-input-close {
      i {
        font-size: 1.5rem;
      }
    }
  }
</style>
