<template>
  <div>
    <data-table
      :headers="headers"
      show-expand
      hide-default-footer
      single-expand
      server-pagination
      endpoint="Issue/GetCurrentRegisterCompanyIssues"
      :search="search"
    >
      <template v-slot:item.companyName="{ item }">
        <router-link :to="`/register-issue/${item.id}`">{{
          item.companyName
        }}</router-link>
      </template>
      <template v-slot:item.reRegistration="{ item }">
        {{
          item.reRegistration ? $t('common.yes') : ''
        }}
      </template>
        <template v-slot:[`item.salesman`]="{ item }">
          <div v-if="item.salesman">
            {{ item.salesman.name }} {{ item.salesman.surname }}
          </div>
        </template>
      <template v-slot:item.requiredDocumentsAttached="{ item }">
        {{
          item.requiredDocumentsAttached ? $t('common.yes') : $t('common.no')
        }}
      </template>
      <template v-slot:item.dateOfRegistration="{ item }">
        {{ item.dateOfRegistration | dateFormat }}
      </template>
      <template v-slot:item.issueState="{ item }">
        {{ getState(item.issueState) }}
      </template>
      <template v-slot:item.currentlyHandledBy="{ item }">
        <div v-if="item.currentlyHandledBy">{{ item.currentlyHandledBy.name }} {{ item.currentlyHandledBy.surname }}</div>
      </template>
      <!-- <template v-slot:item.actions="{item}">
        <v-btn icon>
          <v-icon @click="$router.push({ name: 'CompaniesDetailsCustomerService', params: { id: item.id }  })">mdi-dots-vertical</v-icon>
        </v-btn>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="handleCompanyClick(item)">
              <v-list-item-title
                >{{ $t('users.goToProfile') }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="$permission('IssueTakeover')" link @click="handleIssueClick(item)">
              <v-list-item-title>
                {{ $t('companies.issue') }}
              </v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-6" :colspan="headers.length">
          <h4>{{ $t('companies.issueState') }}</h4>
          <v-data-table
            :headers="detailsHeaders"
            :items="item.assignmentHistory"
            hide-default-footer
          >
            <template v-slot:item.department="{ item }">
              {{ getDepartment(item.department) }}
            </template>
            <template v-slot:item.dateOfAssignment="{ item }">
              {{ item.dateOfAssignment | dateFormat }}
            </template>
            <template v-slot:item.assignedTo="{ item }">
              <div v-if="item.assignedTo">
                {{ item.assignedTo.name }} {{ item.assignedTo.surname }}
              </div>
            </template>
          </v-data-table>
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getEnumDescription } from '@/utils/helpers'
export default {
  name: 'CurrentCompaniesTable',
  computed: {
    ...mapGetters({
      enums: 'enums',
      users: 'users/users'
    })
  },
  props: {
    search: String
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('companies.companyName'),
          value: 'companyName'
        },
        {
          text: this.$t('companies.companyId'),
          value: 'companyDisplayId'
        },
        {
          text: this.$t('companies.vatNumber'),
          value: 'vatNumber'
        },
        {
          text: this.$t('companies.reRegistration'),
          value: 'reRegistration'
        },
        {
          text: this.$t('companies.issueId'),
          value: 'issueDisplayId'
        },
        {
          text: this.$t('companies.requiredDocuments'),
          value: 'requiredDocumentsAttached'
        },
        {
          text: this.$t('companies.dateOfRegistration'),
          value: 'dateOfRegistration'
        },
        {
          text: this.$t('companies.issueState'),
          value: 'issueState'
        },
        {
          text: this.$t('companies.assignedTo'),
          value: 'currentlyHandledBy',
          width: 200
        },
        {
          text: this.$t('companies.salesman'),
          align: 'center',
          value: 'salesman'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      detailsHeaders: [
        {
          text: this.$t('companies.department'),
          value: 'department',
          width: '15%'
        },
        {
          text: this.$t('companies.dateOfAssignment'),
          value: 'dateOfAssignment',
          width: '15%'
        },
        {
          text: this.$t('companies.assignedTo'),
          value: 'assignedTo',
          width: '20%'
        },
        {
          text: '',
          value: '',
          width: '55%',
          sortable: false
        }
      ]
    }
  },
  methods: {
    handleIssueClick (value) {
      const routeData = this.$router.resolve({ name: 'RegisterIssueCustomerService', params: { id: value.id } })
      window.open(routeData.href, '_blank')
    },
    handleCompanyClick (value) {
      const routeData = this.$router.resolve({ name: 'CompaniesDetailsCustomerService', params: { id: value.companyId } })
      window.open(routeData.href, '_blank')
    },
    getState (value) {
      return getEnumDescription(this.enums.issueState, value)
    },
    getDepartment (value) {
      return getEnumDescription(this.enums.departmentTypes, value)
    }
  }
}
</script>
