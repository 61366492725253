<template>
  <v-card class="pa-5">
    <div class="d-flex align-center mb-6">
      <h2 class="text-subtitle-1 font-weight-bold mr-4">
        {{ $t('companies.companiesList') }}
      </h2>
      <div>
        <SearchInput v-model="searchInputValue" />
      </div>
    </div>
    <v-tabs
      v-model="activeTab"
      @change="changeTab"
    >
      <v-tab>{{ $t('companies.tabs.current') }}</v-tab>
      <v-tab>{{ $t('companies.tabs.waitingForApproval') }}</v-tab>
      <v-tab v-if="$permission('ViewRejectedCompanies')">{{ $t('companies.tabs.declined') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-card class="py-5">
          <CurrentCompaniesTable
            v-if="activeTab === 0"
            :search="searchInputValue"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="py-5">
          <WaitingCompaniesTable
            v-if="activeTab === 1"
            :users-to-assign="users"
            :search="searchInputValue"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="$permission('ViewRejectedCompanies')">
        <v-card class="py-5">
          <DeclinedCompaniesTable
            v-if="activeTab === 2"
            :search="searchInputValue"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '@/services'
import CurrentCompaniesTable from './tables/CurrentCompaniesTable.vue'
import DeclinedCompaniesTable from './tables/DeclinedCompaniesTable.vue'
import WaitingCompaniesTable from './tables/WaitingCompaniesTable.vue'
import SearchInput from '@/components/search/SearchInput.vue'

export default {
  name: 'CompaniesIssues',
  components: {
    CurrentCompaniesTable,
    DeclinedCompaniesTable,
    WaitingCompaniesTable,
    SearchInput
  },
  data () {
    return {
      companies: [],
      users: [],
      activeTab: null,
      loading: false,
      searchInputValue: ''
    }
  },
  computed: {
    ...mapGetters({
      userCommonData: 'userCommonData'
    })
  },
  async created () {
    this.activeTab = Number(this.$route.params.activeTab)

    if (this.activeTab === 1 && (this.$permission('AssignIssueToUser') || this.$permission('IssueTakeover'))) {
      const { data } = await Service.get('/Issue/GetUsersToAssign')
      this.users = data
    } else {
      this.users = [this.userCommonData.id]
    }
  },
  methods: {
    async changeTab () {
      await this.$router.push({ name: 'CompaniesIssuesCustomerService', params: { activeTab: this.activeTab } })
    }
  }
}
</script>
