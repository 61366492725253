<template>
  <div>
    <data-table
      :headers="headers"
      server-pagination
      endpoint="/Issue/GetRejectedCompanies"
      hide-default-footer
      :search="search"
    >
      <template v-slot:item.name="{ item }">
        <router-link :to="`/register-issue/${item.id}`">{{
          item.name
        }}</router-link>
      </template>
      <template v-slot:item.reRegistration="{ item }">
        {{
          item.reRegistration ? $t('common.yes') : ''
        }}
      </template>9
      <template v-slot:[`item.dateOfDecline`]="{ item }">
        {{ item.dateOfDecline | dateFormat }}
      </template>
      <template v-slot:[`item.assignedUser`]="{ item }">
        {{ item.assignedUser.name }} {{ item.assignedUser.surname }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="$permission('IssueTakeover')" link @click="handleIssueClick(item)">
              <v-list-item-title>
                {{ $t('companies.issue') }}
              </v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeclinedCompaniesTable',
  computed: {
    ...mapGetters({
      users: 'users/users'
    })
  },
  props: {
    search: String
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('companies.companyName'),
          value: 'name'
        },
        {
          text: this.$t('companies.companyId'),
          value: 'companyDisplayId'
        },
        {
          text: this.$t('companies.vatNumber'),
          value: 'vatNumber'
        },
        {
          text: this.$t('companies.reRegistration'),
          value: 'reRegistration'
        },
        {
          text: this.$t('companies.issueId'),
          value: 'issueDisplayId'
        },
        {
          text: this.$t('companies.dateOfDecline'),
          value: 'dateOfDecline'
        },
        {
          text: this.$t('companies.assignedTo'),
          value: 'assignedUser'
        },
        {
          text: this.$t('companies.reasonOfDecline'),
          value: 'reasonOfDecline'
        }
      ],
      dataTableOptions: this.options
    }
  },
  methods: {
    handleIssueClick (value) {
      const routeData = this.$router.resolve({ name: 'RegisterIssueCustomerService', params: { id: value.id } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
